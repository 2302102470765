import styled from 'styled-components'


const FullContainer = styled.div`
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
`

export default FullContainer