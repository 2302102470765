const breakpts = {
  xs: 393,
  s: 992,
  m: 1280,
  l: 1512,
  xl: 1728,
  xxl: 1920
}

export default breakpts
